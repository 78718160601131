<template>
    <div class="d-flex all-after-header-box">
        <!-- Левое меню модуля -->
        <div 
            :class="{'drawer-left-wrap' : true, 'dlw-large' : (!drawerLeftMenuMini), 'drawer-menu-hover': drawerLeftMenuHover }"
            @mouseenter="LeftMenuMouseenter()"
            @mouseleave="LeftMenuMouseleave()"
        >
            <v-navigation-drawer
                absolute
                permanent
                class="drawer-menu"
                v-model="drawerLeftMenu"
            >

                <template v-if="allowCreateProtocol">
                    <v-divider class="mr-2 ml-2"></v-divider>

                    <v-list
                        nav
                        dense
                        class="dm-actions-list"
                    >
                        <v-list-item-group
                            color="#009c95"
                        >
                            <v-list-item 
                                link
                                class="v-item--active v-list-item--active drawer-menu-list-bigbtn"
                                @click="onCreateClicked"
                            >
                                <v-list-item-icon>
                                    <v-icon>mdi-plus-box</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>{{ $t("Добавить") }}</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </template>

                <v-divider class="mr-2 ml-2"></v-divider>

                <v-list
                    nav
                    dense
                    shaped
                >
                    <v-list-item-group>
                        <v-list-item
                            link
                            :to="{ name: 'Orders' }"
                        >
                            <v-list-item-icon>
                            <v-icon>far fa-clipboard-list</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t("Поручения") }}</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            link
                            :to="{ name: 'ActItems' }"
                        >
                            <v-list-item-icon>
                            <v-icon>far fa-sitemap</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t("Пункты_поручений") }}</v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>

                <v-divider class="mr-2 ml-2" v-if="allowSwetofor"></v-divider>

                <v-list
                    v-if="allowSwetofor"
                    nav
                    dense
                    shaped
                >
                    <v-list-item-group>
                        <v-list-item
                            link
                            :to="{ name: 'ProtocolsSvetofor' }"
                        >
                            <v-list-item-icon>
                            <v-icon>far fa-clipboard-list</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t("Светофор") }}</v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>

                <v-divider class="mr-2 ml-2"></v-divider>

                <v-list
                    nav
                    dense
                    shaped
                >
                    <v-list-item-group>

                        <v-list-item
                            link
                            :to="{ name: 'ProtocolOnExecutionList' }"
                        >
                            <v-list-item-icon>
                            <v-icon>far fa-chalkboard-teacher</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t('На_исполнении') }}</v-list-item-title>
                            <div v-if="!drawerLeftMenuMini" class="dm-counter">{{ onExecutionCount }}</div>
                        </v-list-item>
                        
                        <v-list-item
                            link
                            :to="{ name: 'ProtocolOnControlList' }"
                        >
                            <v-list-item-icon>
                            <v-icon>far fa-user-shield</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t('На_контроле') }}</v-list-item-title>
                            <div v-if="!drawerLeftMenuMini" class="dm-counter">{{ onControlCount }}</div>
                        </v-list-item>


                        <v-list-item
                            link
                            :to="{ name: 'ProtocolOnAdjustmentList' }"
                        >
                            <v-list-item-icon>
                            <v-icon>far fa-file-search</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t('На_проверке')}}</v-list-item-title>
                            <div v-if="!drawerLeftMenuMini" class="dm-counter">{{ onAdjustmentCount }}</div>
                        </v-list-item>
                        
                        <v-list-item
                            v-if="onExecutionControlCount > 0"
                            link
                            :to="{ name: 'ProtocolOnExecutionControlList' }"
                        >
                            <v-list-item-icon>
                            <v-icon>far fa-tasks</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t('Контроль_исполнения') }}</v-list-item-title>
                            <div v-if="!drawerLeftMenuMini" class="dm-counter">{{ onExecutionControlCount }}</div>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>

            </v-navigation-drawer>
        </div>

        <div class="content-box">

            <router-view :key="$route.fullPath" />
            
        </div>

    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    name: "Orders",
    components: {
    },
    computed: {
        ...mapGetters({ drawerLeftMenuMini: 'isDrawerLeftMenuMinimized' }),
        ...mapGetters('global/actionsource', ['getDataSourceType', 'isDataSourceNewRecord'] ),
        ...mapGetters('orders', {
            onControlCount : 'getControlCount', 
            onExecutionCount : 'getExecutionCount', 
            onAdjustmentCount : 'getAdjustmentCount',
            onExecutionControlCount: 'getExecutionControlCount'
        }),
        allowCreateProtocol(){
            let checker = (arr, target) => target.every(v => arr.includes(v));
            return checker(this.$store.getters['global/auth/getUserInfo'].Permissions, ["CreateProtocol", "CanSignChancellery"]);
        },
        allowSwetofor(){
            return this.$store.getters['global/auth/getUserInfo'].Permissions?.includes('ExecutionControl');
        }
    },
    data: () => ({
        drawerLeftMenu: true,
        drawerLeftMenuHover: false,
    }),    
    methods: {
        ...mapActions('global/actionsource', ['loadDataSource']),
        ...mapActions('orders', ['updateCounter']),
        LeftMenuMouseenter() {
            if (this.drawerLeftMenuMini)
                this.drawerLeftMenuHover = true;
        },
        LeftMenuMouseleave() {
            this.drawerLeftMenuHover = false;  
        },
        async onCreateClicked() {
            if (!(this.$route.name == "ProtocolDocument" && this.getDataSourceType == "ProtocolDocument" && this.isDataSourceNewRecord ))
                await this.loadDataSource({ createType: "Documents.ProtocolDocument", navigate: true, common: false });
        },
    },
    async mounted() {
        await this.updateCounter();
    }
}
</script>